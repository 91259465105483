/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, {
  useContext,
} from "react";

import { ContextServiceApi } from "../context/ContextServiceApi";
import { DestinationFeature } from "../components/DestinationFeature";
import { MainLayout } from "../components/layouts/MainLayout";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DestinationCost from "../components/DestinationCost";

export default function NegrilPage() {
  const { location } = useContext(ContextServiceApi);
  const [selectedLocation,setLocation] = useState();
  let { loc } = useParams();

  return (
    <MainLayout>
      <section className="pt-12">
      <DestinationFeature hero place={location[loc]} />
      <DestinationCost place={location[loc]}/>
      </section>
    </MainLayout>
  );
}

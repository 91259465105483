
import React, {
    Component,
    useContext,
    useEffect,
    Fragment,
    useState,
} from "react";
import _ from 'lodash'
import { isBrowser, isMobile } from "react-device-detect";
import moment from "moment";
import { Popover, Transition } from "@headlessui/react";
import { Link, useParams } from "react-router-dom";
import {
    ArrowLeftIcon,
    ArrowRightCircleIcon,
    ArrowUturnLeftIcon,
    Bars3Icon,
    ChatBubbleBottomCenterTextIcon,
    ChatBubbleLeftRightIcon,
    DocumentChartBarIcon,
    InboxIcon,
    PencilSquareIcon,
    QuestionMarkCircleIcon,
    TrashIcon,
    UsersIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronLeftIcon, HomeIcon } from "@heroicons/react/20/solid";

import { ContextServiceApi } from "../../context/ContextServiceApi";
import { DestinationFeature } from "../DestinationFeature";
import { SignatureTourFeature } from "../SignatureTourFeature";


const Tours = [
    {
        name: "Negril",
        description:
            "Get a better understanding of where your traffic is coming from.",
        href: "#negril-feature",
        icon: InboxIcon,
    },
    {
        name: "St. Elizabeth",
        description: "Speak directly to your customers in a more meaningful way.",
        href: "#stelizabeth-feature",
        icon: InboxIcon,
    },
    {
        name: "Ochi Rios",
        description: "Your customers' data will be safe and secure.",
        href: "#ochi-feature",
        icon: InboxIcon,
    },
    {
        name: "Portland",
        description: "Connect with third-party tools that you're already using.",
        href: "#portland-feature",
        icon: InboxIcon,
    },
    {
        name: "Falmouth",
        description: "Connect with third-party tools that you're already using.",
        href: "#falmouth-feature",
        icon: InboxIcon,
    },
];
const metrics = [
    {
        id: 1,
        stat: "8K+",
        emphasis: "Companies",
        rest: "use laoreet amet lacus nibh integer quis.",
    },
    {
        id: 2,
        stat: "25K+",
        emphasis: "Countries around the globe",
        rest: "lacus nibh integer quis.",
    },
    {
        id: 3,
        stat: "98%",
        emphasis: "Customer satisfaction",
        rest: "laoreet amet lacus nibh integer quis.",
    },
    {
        id: 4,
        stat: "12M+",
        emphasis: "Issues resolved",
        rest: "lacus nibh integer quis.",
    },
];
const footerNavigation = {
    tours: [
        { name: "Negril", href: "#negril-feature" },
        { name: "Montego Bay", href: "#montegobay-feature" },
        { name: "St. Elizabeth", href: "#stelizabeth-feature" },
        { name: "Ochi Rios", href: "#ochi-feature" },
        { name: "Portland", href: "#portland-feature" },
        { name: "Falmouth", href: "#falmouth-feature" },
    ],
    support: [],
    company: [
        { name: "Call (876)-888-8888", href: "whatsapp:(876)-888-8888" },
        /* { name: 'Blog', href: '#' },
        { name: 'Jobs', href: '#' },
        { name: 'Press', href: '#' }, */
        { name: "Email Us @ TJVibespreetour.com", href: "#" },
    ],
    legal: [
        { name: "Claim", href: "#" },
        { name: "Privacy", href: "#" },
        { name: "Terms", href: "#" },
    ],
    social: [
        {
            name: "Facebook",
            href: "#",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: "Instagram",
            href: "#",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: "Twitter",
            href: "#",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
            ),
        },
        {
            name: "GitHub",
            href: "#",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
        {
            name: "Dribbble",
            href: "#",
            icon: (props) => (
                <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                    <path
                        fillRule="evenodd"
                        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                        clipRule="evenodd"
                    />
                </svg>
            ),
        },
    ],
};
const images = [
    {
        src: "./assets/images/img_1.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_2.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_3.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_4.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_5.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_6.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_7.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_8.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_9.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_10.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_11.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_12.jpg",
        text: "",
    },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export function NavigationBar(props) {

    const { location } = useContext(ContextServiceApi);
    let { loc } = useParams();

    return (

        <>
            <header>
                <Popover className="relative bg-white">
                    <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
                        <div className="flex justify-start lg:w-0 lg:flex-1">
                            <a href="/" className="flex">
                                <HomeIcon className="h-8"/>
                                <span className="sr-only">TJ Vibes Pree Tours</span>
                                <img className="h-8 w-auto sm:h-10" src="/logo.png" alt="" />
                            </a>
                        </div>
                        <span className="font-extrabold mr-5">TJ Vibes Pree Tours</span>
                        <div className="text-2xl uppercase font-extrabold">{loc}</div>
                        <div className="-my-2 -mr-2 md:hidden">
                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                                <span className="sr-only">Open menu</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                        </div>
                        <Popover.Group as="nav" className="hidden space-x-10 md:flex">
                            <Popover className="relative">
                                {({ open }) => (
                                    <>
                                        <Popover.Button
                                            className={classNames(
                                                open ? "text-gray-900" : "text-gray-500",
                                                "group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                            )}
                                        >
                                            <span>Excursions</span>
                                            <ChevronDownIcon
                                                className={classNames(
                                                    open ? "text-gray-600" : "text-gray-400",
                                                    "ml-2 h-5 w-5 group-hover:text-gray-500"
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>

                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="opacity-0 translate-y-1"
                                            enterTo="opacity-100 translate-y-0"
                                            leave="transition ease-in duration-150"
                                            leaveFrom="opacity-100 translate-y-0"
                                            leaveTo="opacity-0 translate-y-1"
                                        >
                                            <Popover.Panel className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform right-0">
                                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">

                                                    <div className="relative overflow-auto grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                                        <SignatureTourFeature nav />
                                                        {/* Negril Feature Section */}
                                                        <DestinationFeature nav place={location.negril} />
                                                        {/* MontegoBayFeature Feature Section */}
                                                        <DestinationFeature nav place={location.mobay} />
                                                        {/* OchiRiosFeature Feature Section */}
                                                        <DestinationFeature nav place={location.ochi} />
                                                        {/* PortLandFeature Feature Section */}
                                                        <DestinationFeature nav place={location.portland} />
                                                        {/* StElizabethFeature Feature Section */}
                                                        <DestinationFeature nav place={location.stelizabeth} />
                                                        {/* FalmouthFeature Feature Section */}
                                                        <DestinationFeature nav place={location.falmouth} />
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Transition>
                                    </>
                                )}
                            </Popover>
                        </Popover.Group>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-0 transition md:hidden"
                        >
                            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white/40 backdrop-blur-md shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="px-4 pt-4 pb-6">
                                    <div className="flex items-center justify-between">
                                        <div className="flex flex-row items-center gap-4">
                                            <img
                                                className="h-12 w-auto"
                                                src="/logo.png"
                                                alt="TJ VibesPreeTours"
                                            />
                                            <span className="font-bold text-2xl">Excursions</span>
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                                                <span className="sr-only">Close menu</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="mt-6 h-screen">
                                        <div className="relative h-full pb-40 overflow-auto grid gap-6 bg-white/90 rounded-t-md px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                                            <SignatureTourFeature nav/>
                                            {/* Negril Feature Section */}
                                            <DestinationFeature nav place={location.negril} />
                                            {/* MontegoBayFeature Feature Section */}
                                            <DestinationFeature nav place={location.mobay} />
                                            {/* OchiRiosFeature Feature Section */}
                                            <DestinationFeature nav place={location.ochi} />
                                            {/* PortLandFeature Feature Section */}
                                            <DestinationFeature nav place={location.portland} />
                                            {/* StElizabethFeature Feature Section */}
                                            <DestinationFeature nav place={location.stelizabeth} />
                                            {/* FalmouthFeature Feature Section */}
                                            <DestinationFeature nav place={location.falmouth} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </header>
        </>)

}

export default NavigationBar
/* This example requires Tailwind CSS v3.0+ */
import { CurrencyDollarIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/20/solid'
import _ from 'lodash'

import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { InboxIcon } from '@heroicons/react/24/outline'

function MyModal(props) {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  return (
    <>

      <button
        type="button"
        onClick={openModal}
        className={`${!props.link && '!hidden'}rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        Book Now
      </button>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex flex-col w-screen h-screen transform overflow-hidden bg-black/90 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Tour Registration
                  </Dialog.Title>
                  <div className="flex flex-col grow">
                    <iframe src={props.link}
                      className="grow w-full" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                  </div>

                  <div className="w-full grid place-items-center py-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks!
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default function DestinationCost(props) {
  return (
    <div className="bg-gray-900">
      <div className="relative overflow-hidden pt-32 pb-96 lg:pt-40">
        <div>
        </div>
        <div className="relative mx-auto max-w-7xl px-6 text-left lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <p className="indent-8 mt-2 text-4xl font-bold tracking-tight text-white">
              {props.place.title}
            </p>
            <div className="bg-transparent ">
              <p className="indent-8 text-sm font-medium text-gray-200">
                {props.place.package_details}
              </p>
              <div className={`${props.place.package_include.length == 0 && props.place.package_not_include.length == 0 && '!hidden'} mx-auto max-w-7xl divide-y  bg-gray-100/10 rounded-xl divide-gray-900/10 px-0 py-3 sm:py-3 lg:py-3 lg:px-8`}>
                <dl className="divide-y divide-gray-900/10">
                  <div className={`${props.place.package_include.length == 0 && '!hidden'} pt-0 lg:grid lg:grid-cols-12 lg:gap-8`}>
                    <dt className="text-left font-semibold leading-0 text-sky-400 lg:col-span-5 flex gap-2">
                      <CheckIcon className="h-6 w-6 text-sky-400" aria-hidden="true" />Included in Package</dt>
                    <dd className="mt-4 lg:col-span-7 lg:mt-0">
                      <p className="text-left leading-1 text-sky-400">{props.place.package_include}</p>
                    </dd>
                  </div>
                  <div className={`${props.place.package_not_include.length == 0 && '!hidden'} pt-8 lg:grid lg:grid-cols-12 lg:gap-8`}>


                    <dt className="text-left font-semibold leading-0 text-white lg:col-span-5 flex gap-2">
                      <CurrencyDollarIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      <u>NOT</u> Included in Package</dt>
                    <dd className="mt-4 lg:col-span-7 lg:mt-0">
                      <p className="text-left leading-1 text-white">{props.place.package_not_include}</p>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flow-root bg-slate-900 pb-32 lg:pb-40">
        <div className="relative -mt-80">
          <div className="relative z-9 mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 lg:gap-8 ">
              {props.place.tiers?.map((tier) => (
                <div key={tier.name} className="flex flex-col rounded-2xl bg-white shadow-md shadow-indigo-400 ring-1 ring-black/10 p-6">
                  <div className="p-0 sm:p-0">
                    <h3 className="text-3xl uppercase font-extrabold text-center tracking-tight text-indigo-600" id={tier.id}>
                      {tier.name}
                    </h3>
                    <p className="mt-6 text-base text-center leading-7 text-gray-500">{tier.duration}</p>
                    <p className="mt-6 text-base text-center leading-7 text-gray-600">{tier.description}</p>
                  </div>
                  <div className="flex flex-1 flex-col p-2">
                    <div className="flex flex-1 flex-col justify-between rounded-2xl bg-white ring-1 ring-gray-100 p-6 sm:p-8">
                      <ul role="list" className="space-y-6">
                        {tier?.features.map((feature, index) => (
                          <li key={index} className="flex h-auto flex-row items-start">
                            <div className={`${feature.include_entry && 'bg-green-300'} h-full  grid place-items-center rounded-full`}>

                              <CheckIcon className={`${!feature.include_entry && '!hidden'} h-6 w-6 text-indigo-600`} aria-hidden="true" />
                              <CurrencyDollarIcon className={`${feature.include_entry && '!hidden'} h-6 w-6 text-indigo-600`} aria-hidden="true" />
                            </div>
                            <div className="ml-3 text-xl  leading-6 text-gray-600">{feature.name}</div>
                          </li>
                        ))}
                      </ul>
                      <div className="hidden mt-8">
                        <a
                          href={tier.href}
                          className="inline-block w-full rounded-lg bg-sky-600 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-white shadow-md hover:bg-sky-700"
                          aria-describedby={tier.id}
                        >
                          Get started today
                        </a>
                      </div>
                    </div>
                  </div>


                  <div>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">

                      <div className="overflow-hidden rounded-lg bg-green-100 px-4 py-5 shadow-md sm:p-6 ring-1 ring-green-200">
                        <dt className="truncate text-sm font-medium text-gray-500">Regular</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{tier.cost} <small>USD</small></dd>
                        <span className="text-xs font-semibold leading-8 tracking-normal text-gray-500 ">each person</span>
                      </div>
                      <div className={`${!tier.rt_cost && '!hidden'} overflow-hidden rounded-lg bg-indigo-100 px-4 py-5 shadow-md sm:p-6 ring-1 ring-indigo-200`}>
                        <dt className="truncate text-sm font-medium text-gray-500">Round Trip</dt>
                        <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{tier.rt_cost} <small>USD</small></dd>
                        <span className="text-xs font-semibold leading-8 tracking-normal text-gray-500 ">transportion only</span>
                      </div>

                    </dl>
                  </div>

                  <div className={`${!tier.form_link && '!invisible'} w-full grid justify-items-center py-4`}>
                    <MyModal link={tier.form_link} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative mx-auto mt-8 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-md lg:max-w-4xl">
            <div className="flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
              <div className="lg:min-w-0 lg:flex-1">
                <h3 className="text-3xl font-semibold leading-8 tracking-tight text-red-600">Important!</h3>
                <div className="mt-2 text-base leading-7 text-gray-400">
                  <p className='text-indigo-400 font-semibold py-2'>All locations may not be available, therefore your tour cordinator will check availability and inform you in advance.</p>
                  Pickup points varies and your tour cordinator will contact you via Email or WhatsApp and provide the pick up location prior notice. Please note that excessive deviations from the stipulated pick-up location may attract a fee.
                </div>
              </div>
              <div className='hidden'>
                <a
                  href="#"
                  className="inline-block rounded-lg bg-sky-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-sky-700 hover:bg-sky-100"
                >
                  Learn more <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


import { Carousel } from "react-responsive-carousel";
import ImageModal from "./ImageModal";

const images = [
    {
        src: "./assets/images/img_1.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_2.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_3.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_4.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_5.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_6.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_7.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_8.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_9.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_10.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_11.jpg",
        text: "",
    },
    {
        src: "./assets/images/img_12.jpg",
        text: "",
    },
];

let standard = <div className="col-span-2 sm:col-span-2 md:col-span-1 lg:col-span-1 mt-12 sm:mt-16 lg:mt-0 relative  overflow-hidden">
    <div className="place-items-center grid grid-cols-4 gap-2  w-full  p-1">
        {images.map((img, index) => (
            <ImageModal key={index} img={img} />
        ))}
    </div>
</div>

let carousel = <Carousel axis="horizontal" autoPlay infiniteLoop showThumbs={false} showArrows={false} stopOnHover={false} swipeable={false}>
    {images.map((img,index) => (
        <div
            key={index}
            className="grid bg-cover bg-center bg-slate-900 bg-no-repeat p-6 shadow-xl rounded-lg  xs:rounded-none h-[400px] place-items-center"
            style={{ backgroundImage: `url(${img.src})` }}
        >
            <div className="mt-6 text-white  rounded bg-black/10 p-1 w-fit">
                <h3 className="text-lg font-extrabold">{}</h3>
            </div>
        </div>
    ))}

</Carousel>

export default function ImageGallery(props) {
    return props.carousel ? carousel : standard;
}
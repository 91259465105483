import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
/* import * as userService from "../services/user"
import * as authHandler from '/src/handlers/authHandler' */
import {
  ArrowUturnLeftIcon,
  Bars3Icon,
  ChatBubbleBottomCenterTextIcon,
  ChatBubbleLeftRightIcon,
  DocumentChartBarIcon,
  InboxIcon,
  PencilSquareIcon,
  QuestionMarkCircleIcon,
  TrashIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ContextServiceApi } from "./ContextServiceApi";

export default class ProviderServiceApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {
        negril: {
          title: "Negril",
          link: "/excursion/negril",
          description: "Negril is located in western Jamaica and is known for the place you go to relax and soothe your soul. This town is small but there is no limit to what you can do. Have a blast with ATV’s as you get wet and dirty riding through the hills of Jamaica. Let’s have some more fun with horseback riding while exploring the island and capturing breathtaking views. Make your experience even more memorable by stopping by Rick's Cafe with endless cocktails, lively music, excellent food and a cliff to take a jump if you dare to.",
          attractions: [
            {
              name: "Rick’s café",
              description:
                "",
              icon: InboxIcon,
              images: [
                "/assets/images/Negril/ATTRACTIONS/RICKS_CAFE/vibespree-rickscafe-1.jpeg",
                "/assets/images/Negril/ATTRACTIONS/RICKS_CAFE/vibespree-rickscafe-2.jpeg",
                "/assets/images/Negril/ATTRACTIONS/RICKS_CAFE/vibespree-rickscafe-3.jpeg",
                "/assets/images/Negril/ATTRACTIONS/RICKS_CAFE/vibespree-rickscafe-4.jpeg"
              ],
              tiers: ['tier-1']
            },
            {
              name: "Roaring river",
              description:
                "",
              icon: UsersIcon,
              images: [
                "/assets/images/Negril/ATTRACTIONS/ROARING_RIVER/vibespree-roaringriver-1.jpeg",
                "/assets/images/Negril/ATTRACTIONS/ROARING_RIVER/vibespree-roaringriver-2.jpeg",
                "/assets/images/Negril/ATTRACTIONS/ROARING_RIVER/vibespree-roaringriver-3.jpeg",
                "/assets/images/Negril/ATTRACTIONS/ROARING_RIVER/vibespree-roaringriver-4.jpeg"
              ],
              tiers: ['tier-2']
            },
            {
              name: "7miles beach",
              description:
                "",
              icon: TrashIcon,
              images: [
                "/assets/images/Negril/ATTRACTIONS/7MILES/vibespree-7miles-1.jpeg",
                "/assets/images/Negril/ATTRACTIONS/7MILES/vibespree-7miles-2.jpeg",
                "/assets/images/Negril/ATTRACTIONS/7MILES/vibespree-7miles-3.jpeg",
                "/assets/images/Negril/ATTRACTIONS/7MILES/vibespree-7miles-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Jam West",
              description:
                "",
              icon: TrashIcon,
              images: [
                "/assets/images/Negril/ATTRACTIONS/JAMWEST/vibespree-jamwest-1.jpeg",
                "/assets/images/Negril/ATTRACTIONS/JAMWEST/vibespree-jamwest-2.jpeg",
                "/assets/images/Negril/ATTRACTIONS/JAMWEST/vibespree-jamwest-3.jpeg",
                "/assets/images/Negril/ATTRACTIONS/JAMWEST/vibespree-jamwest-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            }
          ],
          tiers: [
            {
              id: 'tier-1',
              name: 'Aqua Sunset',
              href: '#',
              cost: 180.00,
              rt_cost: 320.00,
              duration:"All day",
              description: "",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSeCTkVIvK0gGmIkxZeFDH6nBpPTx0WWL4oSCmqZ-G0s9v9XLg/viewform?embedded=true",
              features: [
                {include_entry:true,name:'Roaring River and Cave'},
                {include_entry:false,name:'7 Mile Beach+ Activities (Parasailing, Snorkeling, Glass bottom boat  etc…)'},
                {include_entry:true,name:'Rick’s Café'},
              ],
            },
            {
              id: 'tier-2',
              name: 'Eco West End',
              href: '#',
              cost: 150.00,
              rt_cost: 320.00,
              duration:"All day",
              description: '',
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSdjRxy86ck7HuPBbMKz0-QzcguRpZk6RSdLzW8T0PH0CV1CUg/viewform?usp=share_link",
              features: [
                {include_entry:false,name:'Jamwest motor sport & Adventure Park(zip line, Off road ATV Adventure'},
                {include_entry:false,name:'Horse Back riding, race car experience etc)'},
                {include_entry:true,name:'Rick’s Café'},
              ],
            },
          ],
          package_details: "",
          package_include: "Private transportation, Beverages while traveling, Rafting where applicable, Entry fees only if you pay per person.",
          package_not_include: "Lunch, Entry fees for round trip, Jamwest motor sport and adventure park fees."


        },
        mobay: {
          title: "Montego Bay",
          link: "/excursion/mobay",
          description:
            "Montego Bay is the capital of St.James located at Jamaica's north coast and is one of the two cities in Jamaica. Montego Bay is one the          most popular tourist destinations in Jamaica with several beaches and resorts. This city is filled with attractions and activities for every tourist interest. You can take a relaxing ride on a hand-crafted raft down the Martha Brae River sipping on fresh coconut water while being surrounded by nature. As well as visit to the Rose Hall Great House which has a lot of historic mansion in Jamaica since the 1770s.Downtown is always a good time too, from duty-free shopping to taking in the action when the sun goes down at Margaritaville location where the night party never stops at the clubs and bars in the area.",
          attractions: [
            {
              name: "Hip strip and shopping",
              description:
                "",
              images: [
                "/assets/images/Mobay/ATTRACTIONS/HIPSTRIP/vibespree-hipstrip-1.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/HIPSTRIP/vibespree-hipstrip-2.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/HIPSTRIP/vibespree-hipstrip-3.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/HIPSTRIP/vibespree-hipstrip-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Margaritaville",
              description:
                "",
              images: ["/assets/images/Mobay/ATTRACTIONS/MAGARITAVILLE/vibespree-magaritaville-1.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/MAGARITAVILLE/vibespree-magaritaville-2.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/MAGARITAVILLE/vibespree-magaritaville-3.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/MAGARITAVILLE/vibespree-magaritaville-4.jpeg"],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Lethe Rafting",
              description:
                "",
              images: ["/assets/images/Mobay/ATTRACTIONS/LETHE_RAFTING/vibespree-lethe-rafting-01.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/LETHE_RAFTING/vibespree-lethe-rafting-02.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/LETHE_RAFTING/vibespree-lethe-rafting-03.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/LETHE_RAFTING/vibespree-lethe-rafting-04.jpeg"],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Doctor’s Cave",
              description:
                "",
              images: [
                "/assets/images/Mobay/ATTRACTIONS/DOCTORCAVE/vibespree-doctorcave-1.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/DOCTORCAVE/vibespree-doctorcave-2.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/DOCTORCAVE/vibespree-doctorcave-3.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/DOCTORCAVE/vibespree-doctorcave-4.jpeg"],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Pier One",
              description:
                "",
              images: [
                "/assets/images/Mobay/ATTRACTIONS/PIERONE/vibespree-pierone-1.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/PIERONE/vibespree-pierone-2.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/PIERONE/vibespree-pierone-3.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/PIERONE/vibespree-pierone-4.jpeg"],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Taboo",
              description:
                "",
              images: [
                "/assets/images/Mobay/ATTRACTIONS/TABOO/vibespree-taboo-1.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/TABOO/vibespree-taboo-2.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/TABOO/vibespree-taboo-3.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/TABOO/vibespree-taboo-4.jpeg"],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "27/27 Lounge",
              description:
                "",
              images: [
                "/assets/images/Mobay/ATTRACTIONS/LOUNGE27/vibespree-lounge27-1.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/LOUNGE27/vibespree-lounge27-2.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/LOUNGE27/vibespree-lounge27-3.jpeg",
                "/assets/images/Mobay/ATTRACTIONS/LOUNGE27/vibespree-lounge27-4.jpeg"],
              tiers: ['tier-1', 'tier-2']
            },
          ],
          tiers: [
            {
              id: 'tier-1',
              name: 'Aqua Jam',
              href: '#',
              cost: 140.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: "",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSfVm49uvEy6_FIcI0VFpT0sCQ5wke9RWnx18-FOYi0Xv9QOLA/viewform?usp=share_link",
              features: [
                {include_entry:true,name:'Lethe Rafting'},
                {include_entry:false,name:'Margaritaville'},
                {include_entry:true,name:'Doctor’s Cave'},
              ],
            },
            {
              id: 'tier-2',
              name: 'Eco Day',
              href: '#',
              cost: 40.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: "2 Hrs",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSfHb16v3MmMJGSE1ysTk7FipJ8iq9rBhzEPe-FG-9Fr3b9xCA/viewform?usp=share_link",
              features: [
                {include_entry:false,name:'Hipstrip and shopping'},
              ],
            },
            {
              id: 'tier-3',
              name: 'Eco Night',
              href: '#',
              cost: 50.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: "",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSeLtdTKEHEOvJtqc4YHkwypawBn2g7lQlhnBBeQsaYwoX0VSg/viewform?usp=share_link",
              features: [
                {include_entry:false,name:'Margaritaville'},
                {include_entry:false,name:'Pier1'},
                {include_entry:false,name:'Taboo'},
                {include_entry:false,name:'27/27 Lounge'},
              ],
            },
          ],
          package_details: "",
          package_include: "",
          package_not_include: ""
        },
        ochi: {
          title: "Ocho Rios",
          link: "/excursion/ochi",
          description:
            "Ocho Rios is a former fishing village located on the north coast of Jamaica. This is home to rainforest, rivers and waterfalls. Ocho Rios has developed into a port town with a cruise ship harbour and a bay beach that is lined with hotels. There is an unlimited amount of activities to explore and enjoy in Ocho Rios. Tourists can take a stop by the Dunn’s River Falls to climb the waterfall as well as take a swim and interact with dolphins at Dolphin Cove. The town has several restaurants to have lunch that cater authentic Jamaican food that will have your mouth watering while sipping and an ice cold Red Stripe Beer. Ocho rios has everything of your interest that will make your Jamaican experience a memorable one.",
          attractions: [
            {
              name: "Dunns River Falls",
              description:
                "",
              images: [
                "/assets/images/Ochi/ATTRACTIONS/DUNNSRIVERFALLS/vibespree-dunnsriverfalls-1.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/DUNNSRIVERFALLS/vibespree-dunnsriverfalls-2.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/DUNNSRIVERFALLS/vibespree-dunnsriverfalls-3.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/DUNNSRIVERFALLS/vibespree-dunnsriverfalls-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Dolphin cove",
              description:
                "",
              images: [
                "/assets/images/Ochi/ATTRACTIONS/DOLPHINCOVE/vibespree-dolphincove-1.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/DOLPHINCOVE/vibespree-dolphincove-2.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/DOLPHINCOVE/vibespree-dolphincove-3.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/DOLPHINCOVE/vibespree-dolphincove-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Blue hole",
              description:
                "",
              images: [
                "/assets/images/Ochi/ATTRACTIONS/BLUEHOLE/vibespree-bluehole-1.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/BLUEHOLE/vibespree-bluehole-2.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/BLUEHOLE/vibespree-bluehole-3.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/BLUEHOLE/vibespree-bluehole-4.jpeg"
              ],
              tiers: ['tier-2']
            },
            {
              name: "Bob Marley 9mile museum",
              description:
                "",
              images: [
                "/assets/images/Ochi/ATTRACTIONS/BOBMARLEY/vibespree-bobmarley-1.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/BOBMARLEY/vibespree-bobmarley-2.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/BOBMARLEY/vibespree-bobmarley-3.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/BOBMARLEY/vibespree-bobmarley-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "GREEN GROTTO CAVE",
              description:
                "",
              images: [
                "/assets/images/Ochi/ATTRACTIONS/GREENGROTTOCAVE/vibespree-greengrottocave-1.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/GREENGROTTOCAVE/vibespree-greengrottocave-2.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/GREENGROTTOCAVE/vibespree-greengrottocave-3.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/GREENGROTTOCAVE/vibespree-greengrottocave-4.jpeg"
              ],
              tiers: ['tier-2']
            },
            {
              name: "Mystic Mountain",
              description:
                "",
              images: [
                "/assets/images/Ochi/ATTRACTIONS/MYSTICMOUNTAIN/vibespree-mysticmountain-1.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/MYSTICMOUNTAIN/vibespree-mysticmountain-2.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/MYSTICMOUNTAIN/vibespree-mysticmountain-3.jpeg",
                "/assets/images/Ochi/ATTRACTIONS/MYSTICMOUNTAIN/vibespree-mysticmountain-4.jpeg"
              ],
              tiers: ['tier-2']
            },
          ],
          tiers: [
            {
              id: 'tier-1',
              name: 'Aqua Explorer',
              href: '#',
              cost: 180.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: "",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSel-Z3idTP50cnwvtg058xoQmevUypKg7QMWZaBF5JJZI_82Q/viewform?usp=share_link",
              features: [
                {include_entry:true,name:'Dunn’s River Falls'},
                {include_entry:true,name:'Blue Hole'},
                {include_entry:true,name:'Rafting'},
              ],
            },
            {
              id: 'tier-2',
              name: 'Deep Explorer',
              href: '#',
              cost: 130.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: '',
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSd0PUur0TF1ktzNBd-lQCNzw1tu4zFigXXvMkW3E8y1-HsEEw/viewform?usp=share_link",
              features: [
                {include_entry:true,name:'Green Grotto Cave'},
                {include_entry:true,name:'Bob Marley Nine Mile Museum'},
              ],
            },
            {
              id: 'tier-3',
              name: 'Wild n Brave',
              href: '#',
              cost: 130.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: '',
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLScA02lVj8zFJwM0kOKiaMjM3WFJX8QX5PC2JxS14IcjGcyElQ/viewform?usp=share_link",
              features: [
                {include_entry:false,name:'Mystic Mountain'},
                {include_entry:false,name:'ATV/ Doon Buggy'},
              ],
            },
          ],
          package_details: "",
          package_include: "Private transportation Beverages while traveling Entry fees where applicable and Rafting",
          package_not_include: "Lunch and Mystic Mountain Activities."
        },
        portland: {
          title: "Portland",
          link: "/excursion/portland",
          description:
            "Portland is a small parish located on the northeast of the island; which is popularly known for its forested coast, sandy beaches and          deep blue waters of the blue lagoon. Portland is a famous destination for many hollywood stars, families and honeymooners. Blue Lagoon is          popularly known from the love and romance film “the blue lagoon”. Take a boat ride across the glistening turquoise water of the blue lagoon          while being mesmerised by the beauty of the lush greenery surrounding. The Blue Lagoon is a must see on your bucket list. Enjoy some          delicious jerk chicken for lunch at the local jerk shops as you soak in the beauty of nature.",
          attractions: [
            {
              name: "Blue lagoon",
              description:
                "",
              images: [
                "/assets/images/Portland/ATTRACTIONS/BLUELAGOON/vibespree-bluelagoon-1.jpeg",
                "/assets/images/Portland/ATTRACTIONS/BLUELAGOON/vibespree-bluelagoon-2.jpeg",
                "/assets/images/Portland/ATTRACTIONS/BLUELAGOON/vibespree-bluelagoon-3.jpeg",
                "/assets/images/Portland/ATTRACTIONS/BLUELAGOON/vibespree-bluelagoon-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Reach falls",
              description:
                "",
              images: [
                "/assets/images/Portland/ATTRACTIONS/REACHFALLS/vibespree-reachfalls-1.jpeg",
                "/assets/images/Portland/ATTRACTIONS/REACHFALLS/vibespree-reachfalls-2.jpeg",
                "/assets/images/Portland/ATTRACTIONS/REACHFALLS/vibespree-reachfalls-3.jpeg",
                "/assets/images/Portland/ATTRACTIONS/REACHFALLS/vibespree-reachfalls-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Somerset falls",
              description:
                "",
              images: [
                "/assets/images/Portland/ATTRACTIONS/SOMERSETFALLS/vibespree-somersetfalls-1.jpeg",
                "/assets/images/Portland/ATTRACTIONS/SOMERSETFALLS/vibespree-somersetfalls-2.jpeg",
                "/assets/images/Portland/ATTRACTIONS/SOMERSETFALLS/vibespree-somersetfalls-3.jpeg",
                "/assets/images/Portland/ATTRACTIONS/SOMERSETFALLS/vibespree-somersetfalls-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Boston Jerk Center",
              description:
                "",
              images: [
                "/assets/images/Portland/ATTRACTIONS/BOSTONJERK/vibespree-bostonjerk-1.jpeg",
                "/assets/images/Portland/ATTRACTIONS/BOSTONJERK/vibespree-bostonjerk-2.jpeg",
                "/assets/images/Portland/ATTRACTIONS/BOSTONJERK/vibespree-bostonjerk-3.jpeg",
                "/assets/images/Portland/ATTRACTIONS/BOSTONJERK/vibespree-bostonjerk-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
          ],
          tiers: [
            {
              id: 'tier-1',
              name: 'Aqua Surf',
              href: '#',
              cost: 250.00,
              rt_cost:0.00,
              duration:"7 Hours",
              description: "",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSewqyeq-545O7Baa_oTnkEBc6BQFLXWx0_KfP3ZEzWprl1x3A/viewform?usp=share_link",
              features: [
                {include_entry:true,name:'Boston Beach and Jerk'},
                {include_entry:true,name:'Somerset falls'},
                {include_entry:true,name:'Reach falls'},
                {include_entry:true,name:'Rafting'},
              ],
            }
          ],
          package_details: "Choose 3 activities, $780.00 round trip(5 persons maximum).",
          package_include: "Private transportation, Beverages while traveling, Rafting where applicable, Entry fees only if you pay per person.",
          package_not_include: "Lunch, Entry fees for round trip and All day Tour"
        },
        stelizabeth: {
          title: "Saint Elizabeth",
          link: "/excursion/stelizabeth",
          description:
            "Saint Elizabeth is the third largest parish in Jamaica, located in the south west of the island. This parish is famous for being one of the first civilised parishes in Jamaica; it is known as the breadbasket of the nation. There are archeological traces of Taino/Arawak existences in the parish.Since the late 90s St.Elizabeth has become a vital tourist destination. Tourists can stop by the Appleton Estate rum distillery for rum tasting or visit the Black River Safari at black river, the longest river in Jamaica to explore mangrove swamps and see nature's species such as crocodiles and different bird species.",
          attractions: [
            {
              name: "Appleton estate run tour",
              description:
                "",
              images: [
                "/assets/images/StElizabeth/ATTRACTIONS/APPLETONESTATE/vibespree-appletonestate-1.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/APPLETONESTATE/vibespree-appletonestate-2.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/APPLETONESTATE/vibespree-appletonestate-3.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/APPLETONESTATE/vibespree-appletonestate-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "lovers leap",
              description:
                "",
              images: [
                "/assets/images/StElizabeth/ATTRACTIONS/LOVERSLEAP/vibespree-loversleap-1.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/LOVERSLEAP/vibespree-loversleap-2.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/LOVERSLEAP/vibespree-loversleap-3.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/LOVERSLEAP/vibespree-loversleap-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "pelican bar",
              description:
                "",
              images: [
                "/assets/images/StElizabeth/ATTRACTIONS/PELICANBAR/vibespree-pelicanbar-1.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/PELICANBAR/vibespree-pelicanbar-2.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/PELICANBAR/vibespree-pelicanbar-3.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/PELICANBAR/vibespree-pelicanbar-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "black river safari",
              description:
                "",
              images: [
                "/assets/images/StElizabeth/ATTRACTIONS/BLACKRIVERSAFARI/vibespree-blackriversafari-1.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/BLACKRIVERSAFARI/vibespree-blackriversafari-2.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/BLACKRIVERSAFARI/vibespree-blackriversafari-3.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/BLACKRIVERSAFARI/vibespree-blackriversafari-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "YS Falls",
              description:
                "",
              images: [
                "/assets/images/StElizabeth/ATTRACTIONS/YSFALLS/vibespree-ysfalls-1.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/YSFALLS/vibespree-ysfalls-2.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/YSFALLS/vibespree-ysfalls-3.jpeg",
                "/assets/images/StElizabeth/ATTRACTIONS/YSFALLS/vibespree-ysfalls-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
          ],
          tiers: [
            {
              id: 'tier-1',
              name: 'Aqua Escape',
              href: '#',
              cost: 160.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: "",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLScVFVp2G_DOJe14vPl2r03ghrCnkU2VFoy-nTX6SmIr9_qssQ/viewform?usp=share_link",
              features: [
                {include_entry:true,name:'YS Falls'},
                {include_entry:false,name:'Pelican Bar'},
                {include_entry:true,name:'Bubbling spring Mineral Bath'},
              ],
            },
            {
              id: 'tier-2',
              name: 'Eco Adventure',
              href: '#',
              cost: 140.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: '',
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSciCno3kg050JSnjwTSQNf4LZugp3MX3p3XsnCDTgHeDe8yEA/viewform?usp=share_link",
              features: [
                {include_entry:false,name:'Lovers Leap'},
                {include_entry:false,name:'Likkle Ochi'},
                {include_entry:false,name:'Pelican Bar'},
              ],
            },
          ],
          package_details: "",
          package_include: "Private transportation, Beverages while traveling, Rafting where applicable, Entry fees only if you pay per person.",
          package_not_include: "Lunch, Entry fees for round trip."
        },
        falmouth: {
          title: "Falmouth",
          link: "/excursion/falmouth",
          description:
            "Falmouth is the capital of the parish Trelawny in Jamaica, with a busy cruise ship port that lies between Ocho Rios and Montego Bay on the          northshore of the island. The cruise port in Falmouth is safest and most hospitable to explore on your own in all Jamaica. Experience the          breathtaking view of mother nature at The Luminous Lagoon Glistening Water which is Jamaica's only natural night time attraction. The          glistening reaction is seen by a boat ride to the middle of the lagoon after nightfall. At any time of the year regardless of weather, this is an experience you will remember forever. Tourists can also enjoy soaring through Jamaica's lush forest on a Zipline and capture the best jungle views from thousands of Feet in the air at Chukka Adventure.",
          attractions: [
            {
              name: "Marta Brae Rafting",
              description:
                "",
              images: [
                "/assets/images/Falmouth/ATTRACTIONS/MARTABRAERAFTING/vibespree-martabraerafting-1.jpeg",
                "/assets/images/Falmouth/ATTRACTIONS/MARTABRAERAFTING/vibespree-martabraerafting-2.jpeg",
                "/assets/images/Falmouth/ATTRACTIONS/MARTABRAERAFTING/vibespree-martabraerafting-3.jpeg",
                "/assets/images/Falmouth/ATTRACTIONS/MARTABRAERAFTING/vibespree-martabraerafting-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
            {
              name: "Luminous lagoon",
              description:
                "",
              images: [
                "/assets/images/Falmouth/ATTRACTIONS/LUMINOUSLAGOON/vibespree-luminouslagoon-1.jpeg",
                "/assets/images/Falmouth/ATTRACTIONS/LUMINOUSLAGOON/vibespree-luminouslagoon-2.jpeg",
                "/assets/images/Falmouth/ATTRACTIONS/LUMINOUSLAGOON/vibespree-luminouslagoon-3.jpeg",
                "/assets/images/Falmouth/ATTRACTIONS/LUMINOUSLAGOON/vibespree-luminouslagoon-4.jpeg"
              ],
              tiers: ['tier-1', 'tier-2']
            },
          ],
          tiers: [
            {
              id: 'tier-1',
              name: 'Aqua Chill',
              href: '#',
              cost: 160.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: "",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLScYz0MS7uvB2sMgJfwEN4alW1CITHRdcfB6EsmQrChoJ6zBgQ/viewform?usp=share_link",
              features: [
                {include_entry:true,name:'Rafting (lime stone body massage)'},
                {include_entry:true,name:'Tubing & Kayaking'},
              ],
            },
            {
              id: 'tier-2',
              name: 'Eco Night Chill',
              href: '#',
              cost: 50.00,
              rt_cost: 0.00,
              duration:"7 Hours",
              description: "",
              form_link:"https://docs.google.com/forms/d/e/1FAIpQLSdMiGCi1uvpFLNKUxnb2ccneCthrjXjrkAOaGMLERRNx8kC6g/viewform?usp=share_link",
              features: [
                {include_entry:true,name:'Glistening waters luminous lagoon'}
              ],
            }
          ],
          package_details: "",
          package_include: "Private transportation, Beverages while traveling, Rafting where applicable, Entry fees only if you pay per person.",
          package_not_include: "Lunch, Entry fees for round trip."
        },
      },
      images: [
        {
          src: "/assets/images/img_1.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_2.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_3.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_4.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_5.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_6.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_7.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_8.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_9.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_10.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_11.jpg",
          text: "",
        },
        {
          src: "/assets/images/img_12.jpg",
          text: "",
        },
      ]
    };
  }

  componentDidMount() { }

  render() {
    return (
      <>
        <ContextServiceApi.Provider value={this.state}>
          {this.props.children}
        </ContextServiceApi.Provider>
      </>
    );
  }
}

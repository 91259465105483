import React, {
    useContext,
} from "react";
import _ from 'lodash'
import { isBrowser, isMobile } from "react-device-detect";
import BookNowModal from "./BookNowModal";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'

function MyModal(props) {
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    return (
        <>

            <button
                type="button"
                onClick={openModal}
                className={`${!props.link && '!hidden'}rounded-md bg-black bg-opacity-70 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
                Book Now
            </button>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="flex flex-col w-screen h-screen transform overflow-hidden bg-black/90 p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Tour Registration
                                    </Dialog.Title>
                                    <div className="flex flex-col grow">
                                        <iframe src={props.link}
                                            className="grow w-full" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                                    </div>

                                    <div className="w-full grid place-items-center py-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Got it, thanks!
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}

export default function CustomizeTour(props) {

    return (
        <>
            <div className="py-6 grid place-items-center" >
                <div className="relative w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all" >
                    <div className="h-36 bg-fixed bg-no-repeat bg-cover mx-auto   xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8"
                        style={{ backgroundImage: `url(../assets/images/customiser.png)` }
                        }> </div>
                    < div className="absolute hover:backdrop-blur-sm z-10 top-0 h-36 w-full bg-black/10 grid place-items-center" >
                        <p className="text-xl font-extrabold text-black bg-white rounded-full px-3 shadow-lg shadow-black/50" > I Want A Custom Tour Package. </p>
                        < MyModal link="https://docs.google.com/forms/d/e/1FAIpQLSe2K6Snx0-Wmff_hY0GMqbcFFXV5rt58kxcrmB8Q4OPyXjPMw/viewform?usp=share_link" />
                    </div>
                </div>
            </div>
        </>
    )

}
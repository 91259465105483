import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import ProviderServiceApi from './context/ProviderServiceApi'
import HomePage from "./pages/HomePage";
import Excursion from "./pages/Excursion";
import SignatureTour from "./pages/SignatureTour";

import { BrowserRouter as Router, Routes,Route, Link } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";

import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { SignatureTourFeature } from "./components/SignatureTourFeature";
// import Amplify from 'aws-amplify';
// import { API, graphqlOperation } from "aws-amplify";
// const awsAmplify = require('aws-amplify');
//const Amplify = awsAmplify.default;
//const API = awsAmplify.API;
//const graphqlOperation = awsAmplify.graphqlOperation;
Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ProviderServiceApi>
  <Router>
    <Routes>
      <Route exact path="/" element={<HomePage/>}/>
      <Route exact path="/signatureTour" element={<SignatureTour/>} />
      <Route exact path="/excursion/:loc" element={<Excursion/>} />
    </Routes>
  </Router>
  </ProviderServiceApi>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, {
  useContext,
  Fragment
} from "react";

import { ContextServiceApi } from "../context/ContextServiceApi";
import { DestinationFeature } from "../components/DestinationFeature";
import { MainLayout } from "../components/layouts/MainLayout";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DestinationCost from "../components/DestinationCost";
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import { CheckCircleIcon } from '@heroicons/react/20/solid'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ImageGallery from "../components/ImageGallery";
import SignatureTourBooking from "../components/SignatureTourBooking";

const includedFeatures = [
  'Private transportation',
  'VIP services',
  'Beverages and Snacks',
  'On-demand Entertainment',
]

function SigTourCost() {
  return (
    <div className="bg-slate-900">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-300 sm:text-4xl lg:text-5xl">
              Simple no-tricks pricing
            </h2>
            <p className="mt-4 text-xl text-gray-200">
              If you're not satisfied, your next trip is free.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-8 bg-white/0 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-100/0" />
          <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-lg overflow-hidden rounded-lg shadow-lg lg:flex lg:max-w-none">
              <div className="flex-1 bg-white px-6 py-8 lg:p-12">
                <h3 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">Private Tour</h3>
                <p className="mt-6 text-base text-gray-500">
                  Groups of TWO(2) to FIVE(5) persons only, we provide a complimentary assortment of refreshments for our passengers.
                </p>
                <div className="mt-8">
                  <div className="flex items-center">
                    <h4 className="flex-shrink-0 bg-white pr-4 text-base font-semibold text-indigo-600">
                      What's included
                    </h4>
                    <div className="flex-1 border-t-2 border-gray-200" />
                  </div>
                  <ul role="list" className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0">
                    {includedFeatures.map((feature) => (
                      <li key={feature} className="flex items-start lg:col-span-1">
                        <div className="flex-shrink-0">
                          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                        </div>
                        <p className="ml-3 text-sm text-gray-700">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="bg-gray-50 py-8 px-6 text-center lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-12">
                <p className="text-lg font-medium leading-6 text-gray-900">Per Person</p>
                <div className="mt-4 flex items-center justify-center text-5xl font-bold tracking-tight text-gray-900">
                  <span>$130</span>
                  <span className="ml-3 text-xl font-medium tracking-normal text-gray-500">USD</span>
                </div>
                <p className="mt-4 text-sm">
                  <a href="#" className="font-medium text-gray-500 underline">
                    Learn about our travelling policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-3">
      <SignatureTourBooking/>
      </div>
    </div>
  )
}


export default function SignatureTour(props) {

  const { location } = useContext(ContextServiceApi);
  const [selectedLocation, setLocation] = useState();
  let { loc } = useParams();
  return (
    <MainLayout>
      <div className="relative overflow-hidden bg-gradient-to-r from-sky-300 to-green-300  text-transparent">
        <div className="relative pt-6 ">

          <main className="mt-16 sm:mt-24">
            <div className="mx-auto max-w-7xl sm:py-5 xs:py-0">
              <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                <div className="px-4 sm:px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                  <div>

                    <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">
                      TJ Signature Tour
                    </h1>
                    <p className="mt-3 text-base text-gray-900 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      The Jamaican lifestyle is one that is fun-filled and creative in every aspect.
                      Here is your chance to learn and experience a deeper look into things that are a part of our culture
                      and lifestyle such as; Ackee, Sugar Cane, patois, reggae music and much more.
                      Jamaica on a whole is a place where you will always find something fun to see/do and explore to
                      learn more about our lifestyle and feel a part of it.
                    </p>
                  </div>
                </div>
                <div className="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0">
                  <div className="sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg xs:rounded-none">
                          <ImageGallery carousel/>
                    </div>
                </div>
              </div>
            </div>
            <SigTourCost />
          </main>
        </div>
      </div>

    </MainLayout>
  )
}

